import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import pic04 from '../images/pic04.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Rafeio.gr</h2>
          <span className="image main"><img src={pic01} alt="" /></span>
          <p>To επάγγελμα του ράφτη απαιτεί πολύ καλή αντίληψη των πραγμάτων και να μπορείς να καταλάβεις κατευθείαν τι τύπο ανθρώπου έχεις απέναντι σου ώστε να του φτιάξεις το ιδανικό ρούχο.
          <br/><br/>Κάθε ένα από τα κοστούμια σχεδιάζονται, κόβονται και ράβονται με στόχο το καλύτερο δυνατό αποτέλεσμα:<br/><br/> «Είναι ακριβώς αυτό που ήθελα!»
          </p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Κοστουμι</h2>
          <span className="image main"><img src={pic02} alt="" /></span>
          <p>Το κοστούμι ορίζεται από τρία στοιχεία: το ύφασμα, το ράψιμο και τη γραμμή. <br/>
          Η διαδικασία για τη δημιουργία του δικού σας κομψού, υψηλής ποιότητας, χειροποίητου κοστουμιού: <br/><br/>
          <i>Επιλέξτε το ύφασμα</i> από τα δειγματολόγια μας. Μπορείτε να βρείτε ύφασμα που ταιριάζει στο γούστο σας αλλά και το διαθέσιμο budget.<br/>
          <i>Διαλέξτε το σχέδιο</i>. Δύο κουμπιά ή τρία; Μεσάτο ή πιο κλασσικό; Πως θα θέλατε το κοστούμι να πέσει στους ώμους σας, τη μέση κτλ. <br/>
          Στη συνέχεια κάνουμε όλες τις <i>απαραίτητες μετρήσεις</i> ώστε το κοστούμι να σας ταιριάζει σαν γάντι.<br/>
          <i>Προσαρμόστε το</i>, κάντε το μοναδικό. Επιλέξτε τους τύπους των κουμπιών, τις τσέπες κτλ. Αυτές οι πινελιές θα κάνουν το κοστούμι σας να ξεχωρίζει από τα υπόλοιπα.<br/>
          <i>Προβάρετε το</i>. Στις πρόβες εξετάζεται με λεπτομέρεια το κοστούμι πάνω σας και επιβεβαιώνεται το τέλειο αποτέλεσμα.</p>
          {close}
        </article>
        <article id="services"className={`${this.props.article === 'services' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
        <h2 className="major">Υπηρεσιες</h2>
        <span className="image main"><img src={pic03} alt="" /></span>
        <p>Aναλαμβάνουμε τις επιδιορθώσεις των Κοστουμιών σας καθώς και των ρούχων σας</p> 
          <ul>
            <li>κόντυμα παντελονιού</li>
            <li>κόντυμα μανίκια</li>
            <li>κόντυμα γύρο</li>
            <li>στένεμα μέσης</li>
            <li>στένεμα πλαϊνές</li>
            <li>στένεμα μηρού</li>
            <li>στένεμα μέσης</li>
            <li>αλλαγή κουμπιά</li>
            <li>αλλαγή φερμουάρ</li>
            <li>αλλαγή φόδρας</li>
            <li>μεταποιήσεις σε κάθε είδους δερμάτινου ρούχου</li>            
          </ul>
          {close}
        </article>
        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Ποιοι ειμαστε</h2>
          <span className="image main"><img src={pic04} alt="" /></span>
          
          <p>Δημιουργούμε για εσάς μοναδικά, χειροποίητα κοστούμια από το 1983. <br/> Η αγάπη για το αντικείμενο και τη λεπτομέρεια μας διαφοροποιεί. Πάνω από 30 χρόνια ράβουμε για εσάς κοιτώντας την λεπτομέρεια και διατηρώντας τα υψηλότερα στάνταρ. <br/> Μην διστάσετε να επισκεφτείτε το κατάστημά μας ή να μας ρωτήσετε και να λύσουμε οποιαδήποτε απορία σας.</p>
          <h4>Εσυ που θα ραψεις το κοστουμι σου;</h4>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Επικοινωνια</h2>
          <h4>Διευθυνση:</h4>
          <Helmet>
           
          </Helmet>
<a href="https://goo.gl/maps/AN84761fM1zAPymJ9" target="_blank">Μαγνησίας 9</a><br/>
<a href="https://goo.gl/maps/AN84761fM1zAPymJ9" target="_blank">Αιγάλεω (κοντά στο σταθμό Μετρό)</a><br/>
<a href="https://goo.gl/maps/AN84761fM1zAPymJ9" target="_blank">Αθήνα, Αττική</a><br/><br/>
<h4>Ωραριο Λειτουργιας:</h4>

Δευτέρα - Τετάρτη - Σαββάτο:<br/> 09:00 - 15:00<br/>
Τρίτη - Πέμπτη - Παρασκευή:<br/>
09:00 - 15:00<br/>
17:30 - 21:00<br/>
Για παραγγελίες και πληροφορίες επικοινωνείτε με τους παρακάτω τρόπους:<br/>
Τηλέφωνο: 210 5911219<br/>
E-mail: sotirismetozis[at]gmail.com<br/><br/>
          <ul className="icons">
            <li><a href="https://www.facebook.com/rafeio.gr" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a href="https://www.instagram.com/rafeio.gr/" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
            
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main