import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; rafeio.gr  - <a href="http://www.rafeio.gr">ΤΟ ΣΩΣΤΟ ΨΑΛΙΔΙ</a>. 
            <br />
            <a target="_blank" href="https://www.facebook.com/rafeio.gr">
                <span className="icon fa-facebook fa-2x"></span>
            </a> 
            {'  '}
            <a target="_blank" href="https://www.instagram.com/rafeio.gr/">
                <span className="icon fa-instagram fa-2x"></span>
            </a> 
            {'  '}
        </p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
