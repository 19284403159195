import React from 'react'
import PropTypes from 'prop-types'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <span className="icon-suit-and-tie-outfit"></span>
        </div>
        <div className="content">
            <div className="inner">
                <h1>ΤΟ ΣΩΣΤΟ ΨΑΛΙΔΙ</h1>
                <p>Σχεδιασμος και δημιουργια μοναδικων χειροποιητων κοστουμιων.<br />
                Προσφερονται υπηρεσιες επιδιορθωσεων και μεταποιησεων.
                <br /><br />
                </p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('intro')}}>Rafeio.gr</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('work')}}>Κοστουμι</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('services')}}>Υπηρεσιες</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>Ο ραφτης</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Επικοινωνια</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
